import React from 'react';
import EthereumProvider from './context/ethereumProvider';

// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes'

function App() {
  return (
    <div>
      <EthereumProvider>
        <MyRouts />
      </EthereumProvider>
    </div>
  );
}

export default App;
