import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Explore from '../components/Explore/ExploreFour';
import Footer from '../components/Footer/Footer';
import Scrollup from '../components/Scrollup/Scrollup';

class ExploreTwo extends Component {
  render() {
  	return (
    	<div className="main">
        <Header />
        <Hero />
        <Explore />
        <Footer />
        <Scrollup />
      </div>
    );
  }
}

export default ExploreTwo;
