import React, { Component } from 'react';
import AuthorProfile from "../AuthorProfile/AuthorProfile";
import EthereumContext from '../../context/ethereum';

class Create extends Component {
    render() {
        return (
            <EthereumContext.Consumer>
            {context => (
                <section className="author-area">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-12 col-md-4">
                                {/* Author Profile */}
                                <AuthorProfile />
                            </div>
                            <div className="col-12 col-md-7">
                                {/* Intro */}
                                <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                                    <div className="intro-content">
                                        <span>Create Token</span>
                                    </div>
                                </div>
                                {/* Item Form */}
                                <div className="item-form card no-hover">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="input-group form-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="inputGroupFile01" onChange={context.captureFile} required="required"/>
                                                    <label className="custom-file-label" htmlFor="inputGroupFile01">{context.imageName ? context.imageName : 'Choose file'}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <input type="text" className="form-control" name="nameToken" placeholder="Item Name" required="required" onChange={context.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea className="form-control" name="descriptionToken" placeholder="Description" cols={30} rows={3} defaultValue={""} onChange={context.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="externalUrl" placeholder="External Url" cols={30} rows={3} defaultValue={""} onChange={context.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mt-3 text-center">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="typeToken" id="multiple" defaultValue="option1" onChange={context.handleChange}/>
                                                    <label className="form-check-label" htmlFor="multiple">Multiple Quantity (ERC1155)</label>
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mt-3 text-center">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="typeToken" id="singular" defaultValue="option2" onChange={context.handleChange} defaultChecked/>
                                                    <label className="form-check-label" htmlFor="singular">Singular Token (ERC721)</label>
                                                </div>
                                            </div>
                                        </div>
                                        {context.typeToken === 'multiple' &&
                                            <div className="col-12 col-md-12">
                                                <div className="form-group">
                                                    <label  htmlFor="quantityToken">Item Quantity</label>
                                                    <input type="number" className="form-control" name="quantityToken" id="quantityToken" placeholder="1" min={1} required="required" onChange={context.handleChange}/>
                                                </div>
                                            </div>
                                        }
                                        {
                                            context.fields.map((field, index) => {
                                                let keyId = `key-${index}`, valueId = `value-${index}`, selectId = `select-${index}`;
                                                return(
                                                    <>
                                                        <div className="col-12 col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor={selectId}>Attribute Type</label>
                                                                <select id={selectId} name={selectId} onChange={context.handleChange}>
                                                                    <option value="none" selected disabled>Select a type</option>
                                                                    <option value="text">Property</option>
                                                                    <option value="boost_percentage">Boost Percentage</option>
                                                                    <option value="number">Stats</option>
                                                                    <option value="boost_number">Boost Number</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor={keyId}>Set Attribute Key</label>
                                                                <input type="text" className="form-control" name={keyId} id={keyId} onChange={context.handleChange} required="required" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor={valueId}>Set Attribute Value</label>
                                                                <input type="text" className="form-control" name={valueId} id={valueId} onChange={context.handleChange} required="required" />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                        {context.fields.length > 1 &&
                                            <>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group text-center">
                                                        <button className="btn mt-3 mt-sm-4" onClick={context.addField}>+</button>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <button className="btn mt-3 mt-sm-4" onClick={context.removeField}>-</button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {context.fields.length === 1 &&
                                            <>
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group text-center">
                                                        <button className="btn mt-3 mt-sm-4" onClick={context.addField}>+</button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="col-12">
                                            <button className="btn w-100 mt-3 mt-sm-4" onClick={() => context.createToken()}>Create Token</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                )}
            </EthereumContext.Consumer>
        );
    }
}

export default Create;