import React, { Component } from 'react';
import axios from 'axios';
import EthereumContext from '../../context/ethereum';

const BASE_URL = "https://my-json-server.typicode.com/themeland/netstorm-json-1/author";

class AuthorProfile extends Component {
    state = {
        data: {},
        socialData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    socialData: res.data.socialData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <EthereumContext.Consumer>
            {context => (
                <div className="card no-hover text-center">
                    <div className="image-over">
                        <img className="card-img-top" src={context.ipfsImageUrl ? context.ipfsImageUrl : '/img/newToken.svg'} alt="" />
                    </div>
                    {/* Card Caption */}
                    <div className="card-caption col-12 p-0">
                        {/* Card Body */}
                        <div className="card-body mt-4">
                            <h5 className="mb-3">{context.nameToken}</h5>
                            <p className="my-3">{context.descriptionToken}</p>
                            <p className="my-3">{context.externalUrl}</p>
                            {
                                context.fields.map((field, index) => {
                                    return(
                                        field["attrKey"] && field["attrValue"] &&
                                        <>  
                                            <div className="col-12 p-0">
                                                <p className="my-3"><b>{field["attrKey"]}</b> : {field["attrValue"]}</p>
                                            </div>
                                        </>
                                    );
                                })
                            }
                            {   context.ipfsImageUrl &&
                                <div className="input-group">
                                    <input className="form-control" value={context.ipfsImageUrl}/>
                                    <div className="input-group-append">
                                        <button disabled><i className="icon-camera" /></button>
                                    </div>
                                </div>
                            }
                            {   context.ipfsMetadataUrl &&
                                <div className="input-group">
                                    <input className="form-control" value={context.ipfsMetadataUrl}/>
                                    <div className="input-group-append">
                                        <button disabled><i className="icon-docs" /></button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )}
            </EthereumContext.Consumer>
        );
    }
}

export default AuthorProfile;