import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import Home from "../themes/home";
import Marketplace from "../themes/marketplace";
import Create from "../themes/create";
import Portfolio from "../themes/portfolio";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/marketplace" component={Marketplace} />
            <Route exact path="/create" component={Create} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;