import React from 'react';
import EthereumContext from '../../context/ethereum';

const Header = () => (
    <EthereumContext.Consumer>
        {context => (
            <header id="header">
                {/* Navbar */}
                <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand">
                    <div className="container header">
                        {/* Navbar Brand*/}
                        <ul className="navbar-nav items mx-auto">
                            <li className="nav-item dropdown">
                                {  window.location.pathname !== '/portfolio' &&
                                    <a className="nav-link" href="/portfolio">Portfolio</a>
                                }
                                {  window.location.pathname !== '/marketplace' &&
                                    <a className="nav-link" href="/marketplace">Marketplace</a>
                                }
                                {  window.location.pathname !== '/create' &&
                                    <a className="nav-link" href="/create">Create Token</a>
                                }
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        )}
    </EthereumContext.Consumer>
);

export default Header;